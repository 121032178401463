import PropTypes from 'prop-types'
import React from 'react'

import './PostImage.scss'

const PostImage = ({ src, title }) => (
  <div className='post-image-container'>
    <img className='post-image' src={src} alt={title} />
  </div>
)

PostImage.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string
}

export default PostImage
