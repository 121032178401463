import PropTypes from 'prop-types'
import React from 'react'

const CategoryTitle = ({ title }) => <h3 className='post-title'>{title}</h3>

CategoryTitle.propTypes = {
  title: PropTypes.string
}

export default CategoryTitle
