/* eslint-disable sonarjs/cognitive-complexity */

import { BLOG_TOKENS } from 'constants/Languages'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Blog from './Common/Blog/Blog'
import BlogCategories from './Common/Blog/BlogCategories'
import layoutContext from './layoutContext'
import SEO from './seo'

const Tags = require('constants/Tags')

const Categories = ({ data, cult }) => {
  useContext(layoutContext).setCult(cult)
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { absolutePath: { regex: "/tags-images/" } }) {
            edges {
              node {
                publicURL
                name
              }
            }
          }
        }
      `}
      render={photos => {
        const { categories } = data
        const edges = categories.edges.filter(
          ({
            node: {
              frontmatter: { lang }
            }
          }) => lang === cult
        )
        const tokens = BLOG_TOKENS[cult]

        let posts = []
        let popularPosts = []
        let postTags = []

        for (let popularPost of edges) {
          let {
            node: {
              frontmatter: { tags }
            }
          } = popularPost
          if ((tags || []).includes('popular')) {
            popularPosts.push(popularPost)
          }
          if (popularPosts.length === 3) {
            break
          }
        }

        for (let post of edges) {
          posts.push(post)
          if (posts.length === 3) {
            break
          }
        }
        let includeTags = []
        for (let posts of edges) {
          let {
            node: {
              frontmatter: { tags }
            }
          } = posts
          tags &&
            tags.map(tag => {
              if (!(includeTags || []).includes(tag) && tag !== 'popular') {
                includeTags.push(tag)
              }
            })
        }

        includeTags.map(tag => {
          Object.keys(Tags).map(key => {
            photos.allFile.edges.map(({ node: { publicURL, name } }) => {
              if (
                tag === Tags[key][cult] &&
                name === key &&
                postTags.length < 3
              ) {
                postTags.push({ tag, tagLink: key, src: publicURL })
              }
            })
          })
        })

        return (
          <>
            <SEO title='Новости и полезности' />
            <div className='medium-container'>
              <h1>{tokens.TITLE}</h1>
              <h2 className='posts-title'>{tokens.NEW}</h2>
              <Blog posts={posts} />
              <h2 className='posts-title'>{tokens.POPULAR}</h2>
              <Blog posts={popularPosts} />
              <h2 className='posts-title'>{tokens.TYPES}</h2>
              <BlogCategories postTags={postTags} cult={cult} />
            </div>
          </>
        )
      }}
    />
  )
}

const EDGES_PT = PropTypes.arrayOf(
  PropTypes.shape({
    node: PropTypes.shape({
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    })
  }).isRequired
)

Categories.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: EDGES_PT
    }),
    categories: PropTypes.shape({
      edges: EDGES_PT
    })
  }),
  cult: PropTypes.string
}

export default Categories
