import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import layoutContext from 'components/layoutContext'

import { resolvePath } from '../../../utils'
import CategoryTitle from './CategoryTitle'
import PostButton from './PostButton'
import PostImage from './PostImage'

const Post = ({ src, tag, cult, tagLink }) => {
  const { langPrefix } = useContext(layoutContext)
  return (
    <div className='post'>
      <PostImage src={src} title={tag} />
      <CategoryTitle title={tag} />
      <PostButton
        cult={cult}
        path={resolvePath(langPrefix, '/blog', tagLink)}
      />
    </div>
  )
}

Post.propTypes = {
  src: PropTypes.string,
  tag: PropTypes.string,
  cult: PropTypes.string,
  tagLink: PropTypes.string
}

export default Post
