import PropTypes from 'prop-types'
import React from 'react'

import Post from './Post'

import './Blog.scss'

const Blog = ({ posts }) => (
  <div className='blog'>
    {posts.map(
      ({
        node: {
          frontmatter: {
            path,
            title,
            description,
            image: {
              childImageSharp: {
                resize: { src }
              }
            }
          }
        }
      }) => (
        <Post
          key={path}
          path={path}
          title={title}
          src={src}
          excerpt={description}
        />
      )
    )}
  </div>
)

Blog.propTypes = {
  posts: PropTypes.array,
  pagination: PropTypes.bool
}

export default Blog
