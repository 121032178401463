import { BLOG_TOKENS } from 'constants/Languages'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import layoutContext from 'components/layoutContext'

import './PostButton.scss'

const PostButton = ({ path }) => {
  const { cult } = useContext(layoutContext)

  return (
    <Link to={path} className='btn btn-primary post-btn'>
      {BLOG_TOKENS[cult].READ}
    </Link>
  )
}
PostButton.propTypes = {
  cult: PropTypes.string,
  path: PropTypes.string
}
export default PostButton
