import PropTypes from 'prop-types'
import React from 'react'

import Category from './Category'

const BlogCategories = ({ postTags, cult }) => (
  <div className='blog'>
    {postTags.map(({ tag, src, tagLink }) => (
      <Category key={src} tag={tag} src={src} tagLink={tagLink} cult={cult} />
    ))}
  </div>
)

BlogCategories.propTypes = {
  postTags: PropTypes.array,
  cult: PropTypes.string
}

export default BlogCategories
