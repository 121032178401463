import PropTypes from 'prop-types'
import React from 'react'

import './PostDescription.scss'

const PostDescription = ({ title, excerpt }) => (
  <>
    <h3 className='post-title'>{title}</h3>
    <p className='post-description'>{excerpt}</p>
  </>
)

PostDescription.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string
}

export default PostDescription
