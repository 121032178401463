import PropTypes from 'prop-types'
import React from 'react'

import PostButton from './PostButton'
import PostDescription from './PostDescription'
import PostImage from './PostImage'

import './Post.scss'

const Post = ({ path, title, src, excerpt }) => (
  <div className='post'>
    <PostImage src={src} title={title} />
    <PostDescription title={title} excerpt={excerpt} />
    <PostButton path={path} />
  </div>
)

Post.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
  excerpt: PropTypes.string
}

export default Post
